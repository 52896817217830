import { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { Auth } from "aws-amplify";
import { login } from "../src/redux/action/auth";
import { validateEmail } from "../src/utils";
import Layout from "../components/includes/UserLayout";
import { I18nContext } from "../components/I18N";

function Login({ auth, login }) {
  const { I18n, langCode } = useContext(I18nContext);
  const router = useRouter();
  const initialState = { email: "", password: "" };

  const [loaded, setLoaded] = useState(false);
  const [input, setInput] = useState({ ...initialState });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  async function signInFacebook() {
    Auth.federatedSignIn({ provider: "Facebook" })
      .then(async (user) => {
        localStorage.setItem("loggedIn", true);
      })
      .catch((err) => {
        console.log("err", err);
        // setError(error);
      });
  }

  async function signInGmail() {
    Auth.federatedSignIn({ provider: "Google" })
      .then(async (user) => {
        localStorage.setItem("loggedIn", true);
      })
      .catch((err) => {
        console.log("err", err);
        // setError(error);
      });
  }

  async function signInLine() {
    Auth.federatedSignIn({ provider: "Line" })
      .then(async (user) => {
        localStorage.setItem("loggedIn", true);
      })
      .catch((err) => {
        console.log("err", err);
        // setError(error);
      });
  }

  const onSubmit = async () => {
    if (isEmpty(input.email.trim()) || isEmpty(input.password)) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return false;
    }
    if (!validateEmail(input.email.trim())) {
      setError("กรุณาอีเมล์ให้ถูกต้อง");
      return false;
    }
    setError(false);
    setDisabled(true);
    await login(input);
  };

  useEffect(() => {
    if (get(auth, "error", false)) {
      setDisabled(false);
      setError(auth.error);
    } else {
      setError(false);
      if (auth.loaded) {
        if (auth.user) {
          router.replace("/");
        } else {
          setDisabled(false);
          setLoaded(true);
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    setError(false);
    setInput({ ...initialState });
  }, []);

  if (!loaded) {
    return <></>;
  }

  return (
    <Layout>
      <div
        className={`${langCode}-font flex-auto w-full px-[16px] lg:px-0 flex items-center justify-center`}
      >
        <div className="w-full lg:w-[592px] my-[40px] mx-auto bg-white p-[30px] rounded-[16px]">
          <div className="w-full lg:w-[380px] mx-auto text-black text-[14px] flex flex-col space-y-[10px]">
            <p className="text-[32px] leading-[40px] font-bold text-center">
              {I18n.get("loginWelcome")}
            </p>
            <p className="text-[14px] leading-[20px] text-center">
              {I18n.get("loginOldUser")}
            </p>
            <p className="text-[16px] text-center">
              {I18n.get("loginNewUser")}{" "}
              <span
                onClick={() => router.push("/register")}
                className="font-semibold text-[#3E92F2] underline cursor-pointer"
              >
                {I18n.get("loginRegister")}
              </span>
            </p>
            <p className="text-[14px] font-bold">{I18n.get("loginEmail")}</p>
            <input
              placeholder="email"
              className="bg-white border border-[#DCDCDC] px-[12px] py-[14px] rounded-[8px]"
              value={get(input, "email", "")}
              onChange={(e) => setInput({ ...input, email: e.target.value })}
            />
            <p className="text-[14px] font-bold">{I18n.get("loginPassword")}</p>
            <div className="row-between w-full border border-[#DCDCDC] px-[12px] py-[14px] rounded-[8px]">
              <input
                placeholder="password"
                type={showPassword ? "text" : "password"}
                className="bg-white focus:outline-none w-full"
                value={get(input, "password", "")}
                onChange={(e) =>
                  setInput({ ...input, password: e.target.value })
                }
              />
              <img
                src={`/images/include/${showPassword ? "eye" : "hidden"}.png`}
                onClick={() => setShowPassword(!showPassword)}
                className="w-[24px] h-[24px] object-contain cursor-pointer"
              />
            </div>
            <span
              onClick={() => router.replace("/reset-password")}
              className="font-semibold text-[#3E92F2] underline cursor-pointer text-right"
            >
              {I18n.get("loginForgetPass")}
            </span>
            {error && <p className="text-red-600">{error}</p>}
            <button
              onClick={onSubmit}
              disabled={disabled}
              className="bg-[#2A2A2A] p-[10px] text-white w-[176px] h-[52px] rounded-[8px] mx-auto"
            >
              <p className="text-[20px] font-semibold">
                {I18n.get("loginText")}
              </p>
            </button>
            <div className="py-[30px]">
              <hr />
            </div>
            <button
              onClick={signInGmail}
              className="bg-white border border-[#DCDCDC] rounded-[8px] wofull h-[52px] flex items-center justify-center text-[#060606] space-x-[8px]"
            >
              <img
                src="/images/auth/google.png"
                className="w-[24px] h-[24px] object-contain"
              />
              <p className="text-[16px] font-semibold">
                {I18n.get("loginWithGoogle")}
              </p>
            </button>
            <button
              onClick={signInFacebook}
              className="bg-[#3E92F2] border border-[#3E92F2] rounded-[8px] wofull h-[52px] flex items-center justify-center text-white space-x-[8px]"
            >
              <img
                src="/images/auth/facebook.png"
                className="w-[24px] h-[24px] object-contain"
              />
              <p className="text-[16px] font-semibold">
                {I18n.get("loginWithFacebook")}
              </p>
            </button>
            <button
              onClick={signInLine}
              className="bg-[#08BF5B] border border-[#08BF5B] rounded-[8px] wofull h-[52px] flex items-center justify-center text-white space-x-[8px]"
            >
              <img
                src="/images/auth/line.png"
                className="w-[24px] h-[24px] object-contain"
              />
              <p className="text-[16px] font-semibold">
                {I18n.get("loginWithLine")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(Login);
