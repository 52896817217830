import Meta from "./Meta";
import { useContext } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import get from "lodash/get";

import Modal from "react-modal";
import { setModal } from "src/redux/action/modal";
import { I18nContext } from "../I18N";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    background: "#00000060",
    transform: "translate(-50%, -50%)",
    padding: 0,
    zIndex: 99,
  },
};

Modal.setAppElement("#__next");
function Layout({
  auth,
  modal,
  children,
  leftContent = false,
  onBack = false,
  isWhite = false,
  setModal,
}) {
  const router = useRouter();
  const { langCode } = useContext(I18nContext);

  return (
    <div>
      <Meta />
      <div
        id="layout"
        className={`${
          isWhite ? "bg-white" : "bg-[#3B3F2F]"
        } w-full min-h-screen flex flex-col`}
      >
        <div
          className="font-[CentraNo2] h-[88px] flex justify-center items-center shadow-xl"
          style={{ filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.24))" }}
        >
          <div className="flex justify-between items-center w-full px-[10px] md:px-[17px] lg:px-0 lg:w-[990px] xl:w-[1197px] lg:mx-auto">
            <div className="flex-1">
              {leftContent ? (
                leftContent
              ) : (
                <>
                  {!isWhite && (
                    <div
                      onClick={onBack ? onBack : () => router.back()}
                      className="cursur-pointer w-[52px] h-[52px] rounded-full bg-[#6D7260] flex items-center justify-center"
                    >
                      <img
                        alt="user"
                        src="/images/include/left.png"
                        className="w-[14px] h-[14px] object-contain"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <img
              alt="user"
              src={
                isWhite
                  ? "/images/include/logo.png"
                  : "/images/include/logo-white.png"
              }
              onClick={() => router.replace("/")}
              className="cursor-pointer w-[72px] h-[24px] lg:w-[120px] lg:h-[40px]"
            />
            {get(auth, "user", false) ? (
              <div
                onClick={() => router.replace("/profile")}
                className="cursor-pointer flex-1 flex items-center justify-end space-x-[8px]"
              >
                <img
                  alt="user"
                  src="/images/include/user.png"
                  className="h-[24px] w-auto object-contain"
                />
                <p className="text-white small">
                  {get(auth, "user.firstName", "")}
                </p>
              </div>
            ) : (
              <div className="flex-1" />
            )}
          </div>
        </div>
        <main className={`flex-auto w-full flex flex-col ${langCode}-font`}>
          {children}
        </main>
      </div>
      <Modal
        isOpen={get(modal.content, "name", "") !== ""}
        style={customStyles}
        overlayClassName="modal-overlay"
      >
        <div className="z-50 w-screen h-screen flex items-center justify-center">
          <div
            className={`${langCode}-font w-[80%] md:w-[600px] rounded-[16px] p-[36px] mx-auto flex flex-col text-thai bg-white`}
          >
            <div className="flex justify-between">
              <p className="text-[24px] font-semibold">
                {langCode === "en" &&
                get(modal.content, "nameEN", false) &&
                get(modal.content, "nameEN", false) !== ""
                  ? get(modal.content, "nameEN", "")
                  : get(modal.content, "name", "")}
              </p>
              <img
                alt="user"
                src="/images/close.png"
                onClick={() => {
                  setModal(false);
                }}
                className="cursor-pointer w-[32px] h-[32px]"
              />
            </div>
            <div
              className="mt-[16px] text-[14px] max-h-[60vh] overflow-auto"
              dangerouslySetInnerHTML={{
                __html:
                  langCode === "en" &&
                  get(modal.content, "safetyGuideEN", false) &&
                  get(modal.content, "safetyGuideEN", false) !== ""
                    ? get(modal.content, "safetyGuideEN", "")
                    : get(modal.content, "safetyGuide", ""),
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  modal: state.modal,
});

export default connect(mapStateToProps, { setModal })(Layout);
