import get from "lodash/get";
import find from "lodash/find";

import { API, graphqlOperation } from "aws-amplify";
import { searchOrders, getProductDiscount } from "./graphql/queries";

import moment from "moment";

export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export function validatePhoneWithCode(phone) {
	const re = /^\+?\d{11}$/;
	return re.test(String(phone).toLowerCase());
}

export function validatePhone(phone) {
	const re = /^0?\d{9}$/;
	return re.test(String(phone).toLowerCase());
}

export function submitGA(data) {
	const isProduction =
		window.location.origin === "https://www.sayhibro.com" ||
		window.location.origin === "https://en.sayhibro.com";
	if (isProduction && window?.dataLayer) {
		window.dataLayer.push(data);
	}
}

/**
 * Get Current Order Status
 * @param {*} userID
 * @param {*} categoryID
 * @returns
 * - 0 //No order
 * - 1 //Pending
 * - 2 //In progress
 * - 3 //Complete with deadline
 * - 4 //Completed
 */
export const getOrderState = async ({ userID, categoryID }) => {
	const result = await API.graphql(
		graphqlOperation(searchOrders, {
			sort: { direction: "desc", field: "updatedAt" },
			limit: 1,
			filter: {
				and: [
					{ status: { ne: "CANCELED" } },
					{ status: { ne: "EXPIRED" } },
					{ status: { ne: "DOCTOR_REJECTED" } },
					{ status: { ne: "DISTRIBUTION_REJECTED" } },
					{ status: { ne: "TX_REJECTED" } },
				],
				type: { eq: "MANUAL" },
				categoryID: { eq: categoryID },
				userID: { eq: userID },
			},
		}),
	);
	const inprogressOrder =
		get(result, "data.searchOrders.items.0", false) ?? false;

	if (!inprogressOrder) {
		return 0;
	} else {
		//If PENDIND exist, goto checkout
		if (inprogressOrder.status === "PENDING") {
			return 1;
		}
		//If PAID, IN_REVIEW, DOCTOR_APPROVED, DISTRIBUTION_APPROVED exist, goto order history
		if (
			inprogressOrder.status === "PAID" ||
			inprogressOrder.status === "IN_REVIEW" ||
			inprogressOrder.status === "DOCTOR_APPROVED" ||
			inprogressOrder.status === "DISTRIBUTION_APPROVED"
		) {
			return 2;
		}

		//If COMPLETED exist
		if (inprogressOrder.status === "COMPLETED") {
			const discountResult = await API.graphql(
				graphqlOperation(getProductDiscount, {
					id: get(inprogressOrder, "items.0.productDiscountID"),
				}),
			);
			const month = get(discountResult, "data.getProductDiscount.month");
			//If subscription > 1 and createdAt + subscription month - 15 days > today, goto order history
			const deadline = moment(inprogressOrder.createdAt)
				.add(month, "months")
				.subtract(15, "days");
			if (get(month > 1 && deadline.diff(moment(), "days") > 0)) {
				return 3;
				//else return follow up questionnaire
			} else {
				return 4;
			}
		}
	}
	return 0;
};
